<template>
  <div class='lg:py-4'>
    <table class='mt-4 text-xs lg:text-base'>
      <thead>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-left border-r border-b border-t border-l'>투자일자</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-left border-r border-b border-t'>매매구분</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>투자단가(원)</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>투자수량(주)</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>투자원금(백만원)</th>
        <th class='bg-gray-100 font-gray-700 whitespace-no-wrap px-1 lg:px-2 py-2 lg:py-3 text-right border-r border-b border-t'>투자밸류(백만원)</th>
      </thead>
      <tbody>
        <tr v-for='fill in filteredTmsPiFills'
          :key='`fill-${fill.id}`'
          class='font-normal border'>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-left  border-r border-l whitespace-no-wrap'>{{ fill.createdOn }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-left  border-r whitespace-no-wrap'>{{ fillType(fill.action) }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(fill.price) }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(fill.quantity) }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-right border-r'>{{ toNumber(fill.transactionValue) }}</td>
          <td class='px-1 py-1 lg:px-2 lg:py-2 text-right border-r'></td>
        </tr>
      </tbody>
    </table>
    <button
      class='mt-2 px-2 lg:px-4 py-1 lg:py-2 font-bold uppercase rounded-md border border-gray-300 hover:border-gray-500 text-xs lg:text-sm'
      @click='copyToClipboard'>
      Copy To Clipboard
    </button>
  </div>
</template>

<script>
import numbro from 'numbro'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PiPortfolioSummaries',
  data () {
    return {
      sumInvestmentValue: 0,
      totMarketValue: 0,
      marketValue: 0,
      sumInvestmentQuantity: 0,
      averageInvestmentValue: 0,
    }
  },
  computed: {
    ...mapState('piPortfolios', [
      'drilldownPiPortfolio',
      'tmsPiFills',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    showTotalRow () {
      return this.filteredTmsPiFills.length > 1
    },
    filteredTmsPiFills () {
      return this.tmsPiFills.filter(fill => fill.shareCode === this.drilldownPiPortfolio.shareCode)
    },
  },
  methods: {
    oldOrNewTagStyle (oldOrNew) {
      return (oldOrNew === '신주') ? 'bg-green-100 text-green-800 px-1 rounded lg:rounded-md text-xs lg:text-sm py-0 lg:py-1 border border-green-200' : 'opacity-50 bg-blue-100 text-blue-600 px-1 rounded lg:rounded-md text-xs lg:text-sm py-0 lg:py-1 border border-blue-200'
    },
    marketAverageValue () {
      if (this.marketValue <= 0 || this.totMarketValue <= 0)
        return 0
      else
        return this.toNumber(this.totMarketValue / this.marketValue)
    },
    toNumber (number) {
      if (number) {
        return numbro(parseInt(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    copyToClipboard () {
      let string = ''
      string = this.tableHeaderString ()
      this.filteredTmsPiFills.forEach(fill => {
        string += this.convertDataToString(fill)
      })
      string += this.tableFooterString ()
      this.$copyText(string)
    },
    tableHeaderString () {
      let string = ''
      string += '매매일자' + '\t'
      string += '자산명' + '\t'
      string += '매매구분' + '\t'
      string += '투자단가(원)' + '\t'
      string += '투자수량(주)' + '\t'
      string += '투자원금(백만원)' + '\t'
      string += '투자밸류(백만원)' + '\t'
      string += '\n'

      return string
    },
    convertDataToString(fill) {
      let string = ''
      string += fill.createdOn + '\t'
      string += fill.name + '\t'
      string += fill.action + '\t'
      string += this.toNumber(fill.price) + '\t'
      string += this.toNumber(fill.quantity) + '\t'
      string += this.toNumber(fill.transactionValue) + '\t'
      string += 0 + '\t'
      string += '\n'

      return string
    },
    tableFooterString () {
      let string = ''
      string += '합계' + '\t'
      string += '\t'
      string += '\t'
      string += this.toNumber(this.averageInvestmentValue) + '\t'
      string += this.toNumber(this.sumInvestmentQuantity) + '\t'
      string += this.toNumber(this.sumInvestmentValue) + '\t'
      string += this.marketAverageValue() + '\t'
      string += '\n'

      return string
    },
    fillType (action) {
      return action === 'buy' ? '매수' : '매도'
    }
  },
}
</script>
